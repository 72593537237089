import React from 'react';

const IconWordPress = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    fill="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    className="feather-wordpress"
    style={{enableBackground:"new 0 0 64 64"}}>
    <title>WordPress</title>
    <path d="M32,1C14.8,1,1,14.9,1,32s13.8,31,31,31s31-14,31-31S49,1,32,1z M59.5,32c0,10.4-5.8,19.4-14.2,24l8-20.6
	c0.4-1.1,3.2-7.9,4.2-13.7C58.8,25,59.5,28.5,59.5,32z M37.9,18.5h-3.4H23.8c-0.6,0-1,0.4-1,1s0.4,1,1,1h2l3.5,9l-4.2,10.8
	l-7.6-19.9h2.4c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4.1l-6.6-0.1C13.7,10.4,22.4,5.2,32,5.2c7,0,13.5,2.8,18.3,7.3
	c-2.5,0.4-4.5,2.4-5.1,4.8c-0.6,2.8,0.4,5.1,1.5,7.2c1.1,2.3,2.3,4.4,1.4,6.9L44.4,41l-7.9-20.6h1.5c0.6,0,1-0.4,1-1
	C39,18.9,38.3,18.5,37.9,18.5z M4.5,32c0-3.5,0.7-6.9,2-10L20,56.7C10.7,52.3,4.5,42.9,4.5,32z M32.3,37.9l8,20.3
	c-2.7,0.8-5.4,1.3-8.3,1.3c-2.7,0-5.2-0.3-7.6-1.1L32.3,37.9z"/>
  </svg>
);

export default IconWordPress;
