import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import { version } from 'react-dom';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
  .brand-text {
    color: var(--green);
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'JavaScript (ES6+)',
    'HTML',
    '(S)CSS',
    'PHP',
    'MySQL',
    'WordPress',
    'Gutenburg',
    'Laravel',
    'React',
    'Vue'
  ];
  const loves = [ 'Nuxt.js', 'Node.js', 'Redux', 'Next.js' ];
  const version_controls = [ 'Git', 'SVN' ];
  const tools= [ 'Chrome', 'VS Code/PHPStorm', 'Slack', 'Trello', 'Webpack/Gulp', 'NPM/yarn', 'PostMan' ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Kamal and I enjoy creating things that live on the internet. My
              interest in web development started back in 2013 when I decided to try editing custom
              WordPress themes — turns out hacking together a custom reblog button taught me a lot
              about HTML, CSS &amp; PHP !
            </p>

            {/**<p>
              Shortly I'm Studying at{' '}
              <a
                href="https://www.nu.ac.bd/"
                title="National University, Bangladesh"
                rel="noopener noreferrer"
                target="_blank">
                {' '}
                NU{' '}
              </a>
              , I joined the developer team at{' '}
              <a href="https://themebucket.net/" rel="noopener noreferrer" target="_blank">
                ThemeBucket
              </a>{' '}
              where I work on a very interesting project.
            </p>

            <p>
              Though my major is <span className="brand-text">Bachelor of Social Science</span>, I've
              chased my dream and passion about being a web developer. Here I am today!
            </p> **/}
            <p>
            I've been deeply immersed in the world of web development for the past six years, where I'm engaged in captivating projects that fuel my passion for coding. 
            Alongside this journey, I've pursued my dream of becoming a web developer, and I'm thrilled to be living it today.
            </p>
            <p>
            With a focus on mastering PHP, JavaScript, WordPress, MySQL, and Laravel, among other technologies, I'm dedicated to honing my skills and becoming a highly proficient developer. 
            Looking ahead, I envision myself continuing to grow and excel in the field, contributing innovative solutions to projects that inspire creativity and drive positive change. 
            My ultimate goal is to establish a fulfilling career in web development, leveraging my technical expertise to make a meaningful impact in the industry.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
          <div className="mt-20">
            <p>I'm also love these:</p>
          </div>
          <ul className="skills-list">
            {loves && loves.map((love, i) => <li key={i}>{love}</li>)}
          </ul>
          <div className="mt-20">
            <p>Version Control</p>
          </div>
          <ul className="skills-list">
            {version_controls && version_controls.map((control, i) => <li key={i}>{control}</li>)}
          </ul>
          <div className="mt-20">
            <p>Tools I use</p>
          </div>
          <ul className="skills-list">
            {tools && tools.map((tool, i) => <li key={i}>{tool}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
